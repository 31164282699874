body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
    color: #333;
}

.navbar {
    background-color: #00274d; /* Dark blue background */
    border-bottom: 3px solid #00a3e0; /* Cyan border */
    padding: 15px 0;
    z-index: 1000; /* Ensure navbar is above other content */
}

.navbar-brand {
    font-size: 1.8rem; /* Increased brand font size */
    font-weight: bold;
}

.nav-link {
    font-size: 1.2rem; /* Larger font size for buttons */
    color: #e0e0e0;
    margin-right: 20px; /* Adjusted margin for space between links */
    padding: 10px 15px; /* Uniform padding for nav links */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.nav-link:hover {
    background-color: #00a3e0; /* Cyan background on hover */
    color: #ffffff; /* White text on hover */
}

.navbar-nav .nav-item:last-child {
    margin-right: 0; /* Ensure no extra margin on the last item */
}

.nav-dropdown .dropdown-item {
    padding: 10px 20px; /* Increase padding for dropdown items */
}

.nav-dropdown .dropdown-item:hover {
    background-color: #00a3e0; /* Change background color on hover */
    color: #ffffff; /* Change text color on hover */
}

/* Ensure dropdowns are properly positioned and visible */
.dropdown-menu {
    position: absolute; /* Ensure dropdown is positioned absolutely */
    display: block; /* Always display dropdown */
    z-index: 1000; /* Ensure dropdown is above other content */
}
