/* navCustom.css */

/* General Styles */
.navbar {
  border-radius: 0.5rem !important; /* Rounded edges for the navbar */
  /* overflow: hidden;  Prevents overflow for rounded corners */
}

/* Navbar Brand */
.navbar-brand img {
  transition: transform 0.3s; /* Smooth logo scaling */
}

.navbar-brand img:hover {
  transform: scale(1.05); /* Slightly enlarge logo on hover */
}

/* Navbar Links */
.navbar-nav .nav-link {
  color: #343a40 !important; /* Default text color */
  font-weight: 500; /* Medium weight for better visibility */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  border-radius: 0.25rem !important; /* Rounded edges for links */
}

.navbar-nav .nav-link:hover {
  background-color: #007bff !important; /* Background color on hover */
  color: #fff !important; /* White text color on hover */
}

/* Dropdown Menu */
.dropdown-menu {
  border-radius: 0.5rem !important; /* Rounded edges for dropdown */
  padding: 0.5rem 0; /* Padding for dropdown items */
  position: absolute; /* Position dropdown */
  z-index: 1000; /* Make sure dropdown is on top */
}

.dropdown-item {
  color: #343a40 !important; /* Default dropdown text color */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  border-radius: 0.25rem !important; /* Rounded edges for dropdown items */
}

.dropdown-item:hover {
  background-color: #007bff !important; /* Background color on hover */
  color: #fff !important; /* White text color on hover */
}

/* Active Links */
.nav-link.active,
.dropdown-item.active {
  background-color: #007bff !important; /* Active background color */
  color: #fff !important; /* Active text color */
}

/* Button Styling */
.btn-link {
  color: #007bff !important; /* Default button color */
  font-weight: 500; /* Medium weight for better visibility */
}

.btn-link:hover {
  color: #0056b3 !important; /* Darker blue on hover */
}

/* Spacing Adjustments */
.nav-item {
  margin: 0 0.5rem; /* Space between navbar items */
}

.nav-dropdown {
  margin-right: 1rem; /* More space for the last menu option */
}

/* Icon Styles */
.nav-link .fa,
.dropdown-item .fa {
  margin-right: 0.5rem; /* Space between icon and text */
}

/* Shaking effect on hover removal */
.navbar-collapse {
  transition: none !important; /* Disable transition for collapsing */
}

/* Make dropdowns aligned properly */
.dropdown-menu {
  margin-top: 0.5rem; /* Spacing for dropdowns */
  max-height: 300px; /* Limit the height */
  overflow-y: auto;  /* Enable vertical scrolling */
  min-width: 200px; /* Adjust this value as needed */
}

/* Rounded edges for button */
.btn {
  border-radius: 0.25rem; /* Rounded edges for buttons */
}

/* In your navCustom.css file */
.nav-dropdown-menu {
  position: absolute !important; /* Ensures it's positioned properly */
  z-index: 1050; /* Bootstrap default z-index for dropdown */
}

/* Adjust margins if needed */
.nav-dropdown-menu {
  margin-top: 0.5rem; /* Add spacing if items are too close to the navbar */
}