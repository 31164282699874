
  
 /* Reset some basic styles */
 * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* AWS-like clean style */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
  color: #333;
}

header {
  background-color: #232f3e; /* Dark navy blue, similar to AWS */
  color: white;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

  .navbar {
    background-color: #34495e;
  }
  
   /*.navbar-brand {
   color: white !important;
}*/
 

 /* GitHub-Style Copy Button */
 .copy-btn {
  display: inline-block;
  padding: 5px 12px;
  background-color: #f6f8fa;
  border: 1px solid #d1d5db;
  color: #24292e;
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

/* Hover Effect */
.copy-btn:hover {
  background-color: #f3f4f6;
  border-color: #bcbcbc;
}
  
  .form-group label {
    font-weight: bold;
  }
  
  .request-btn {
    margin-top: 20px;
  }
  
  .header-row {
    margin-bottom: 10px;
  }
  
  .remove-header-btn {
    background-color: red;
    color: white;
    border: none;
  }
  
  /* Enhanced Tab Styles */
  .nav-tabs {
    border-bottom: 2px solid #34495e;
  }
  
  .nav-tabs .nav-link {
    border: none;
    color: #34495e;
    padding: 10px 20px;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
    margin-right: 10px;
  }
  
  .nav-tabs .nav-link:hover {
    background-color: #f0f0f0;
    color: #2c3e50;
  }
  
  .nav-tabs .nav-link.active {
    background-color: #34495e;
    color: white;
    border-radius: 5px;
  }
  
  .tab-content {
    margin-top: 20px;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 5px;
    background-color: #f8f9fa;
  }
  
  .tab-pane textarea {
    border: none;
    background-color: #e9ecef;
    font-family: monospace;
  }
  

 

header nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.nav-left {
  display: flex;
  align-items: center;
}

.nav-left .logo {
  height: 40px;
  margin-right: 15px;
}

.nav-left h1 {
  color: white;
  font-size: 24px;
  font-weight: normal;
}

.nav-right {
  list-style-type: none;
  display: flex;
  align-items: center;
}

.nav-right li {
  margin-left: 20px;
}

.nav-right li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.nav-right li a:hover {
  color: #f90; /* Add a subtle AWS-like hover color */
}
/*
.container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

*/
/* Iframe content styling */
.content-frame {
  width: 100%;
  height: 600px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}



body {
  background-color: #f4f6f9;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  /*padding: 30px;*/
}
.card {
  background-color: #ffffff;
  border: 1px solid #e3e6f0;
  border-radius: 8px;
  border-radius: 1rem; /* Rounded corners */

  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
/* src/Login.css */


.alert {
  border-radius: 0.5rem; /* Rounded alert */
}


h1 {
  font-size: 24px;
  color: #343a40;
  margin-bottom: 20px;
}
label {
  font-weight: 600;
  color: #495057;
}
.checkboxes div {
  margin: 10px 0;
  display: flex;
  align-items: center;
}
.checkboxes label {
  margin-left: 10px;
}
#responseKeys {
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #e3e6f0;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
}
#submitButton {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  /*transition: background-color 0.3s;*/
}
#submitButton:hover {
  background-color: #0056b3;
}
#output h3 {
  margin-top: 20px;
  color: green;
  font-weight: normal;
}
.form-control {
  border-radius: 5px;
}
.form-group {
  margin-bottom: 20px;
}