.grid-card {
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
    padding: 3px; /* Padding inside the card */
}

.add-button {
    margin-bottom: 20px; /* Space below the button */
}

.grid-table th {
    background-color: #f0f2f5; /* Light gray background for table header */
    color: #1890ff; /* Primary color for header text */
    font-weight: bold; /* Bold text for header */
}

.grid-table td {
    transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.grid-table tr:hover td {
    background-color: #e6f7ff; /* Light blue background on row hover */
}

.grid-table {
    width: 100%; /* Ensure table takes full width */
    overflow: auto; /* Enable scrolling */
}

.grid-table .ant-table {
    width: 100%; /* Ensure the ant-table takes full width */
}

.grid-table .ant-table-tbody > tr > td {
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}

.grid-table .ant-table-tbody > tr > td.overflow-cell {
    max-width: 150px; /* Set a max width for long columns */
    overflow: hidden; /* Hide overflow */
    white-space: nowrap; /* Prevent text wrapping */
    text-overflow: ellipsis; /* Show ellipsis for overflow */
}



.grid-modal {
    width: 600px; /* Fixed width for modal */
}

.grid-modal .ant-modal-content {
    border-radius: 8px; /* Rounded corners for modal */
}


.grid-table .ant-table {
    border-radius: 8px;
    overflow: hidden;
}

.grid-table .ant-table-thead > tr > th {
    background-color: #1890ff;
    color: #fff;
    font-weight: bold;
    text-align: center;
}

.grid-table .ant-table-tbody > tr:hover {
    background-color: #e6f7ff;
}

.grid-table .ant-table-tbody > tr > td {
    text-align: center;
}

.grid-table .ant-btn {
    border-radius: 4px;
}

.grid-table .ant-form-item {
    margin-bottom: 12px; /* Reduce spacing between form items */
}

.grid-table .ant-input {
    width: 500px; /* Reduce input field size */
}
